//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {bgimgUrl2,ewmUrl} from '/src/utils/config.js'
import audioImg from '/src/components/audioAutoPlay.vue'
export default {
	name: "notice",
	components: {
		audioImg
	},
	data() {
		return {
			flag: true,
			timer1: null,
			num: 20,
			bgimgUrl2:'',
			ewmUrl:'',
			id: "",
		};
	},
	created() {
		this.id = this.$route.query.id;
		this.bgimgUrl2 = bgimgUrl2;
		this.ewmUrl = ewmUrl;
		this.countDown();
		
		localStorage.setItem("flag", "0");
	},
	methods: {
		countDown() {
			this.timer1 = setInterval(() => {
				this.num--;
				if (this.num == 0) {
					clearInterval(this.timer1);
					this.flag = false;
				}
			}, 1000);
		},
		handleClick() {
			localStorage.setItem("flag", "1");
			// this.$router.push('dati')
			// this.$router.push({name:'dati',query:{id:this.$route.query.id}})
			this.$router.push({
				name: "dati",
				query: {
					id: this.id
				}
			});
		},
	},
	beforeDestroy() {
		clearInterval(this.timer1);
	},
};
